import React, { useState ,useEffect} from 'react'
import '../assets/scss/pricing.css' 
import HambergaerMenu from './HambergaerMenu'
import Header from './Header'
import $ from 'jquery';
const Pricing = () => {
    const [hamshow , setHamshow ] = useState(false)
    useEffect(()=>{
        if(hamshow){
            function name() {
            $('.pricing__cotainer__scroll').addClass('pricing__cotainer__scroll2').removeClass('pricing__cotainer__scroll');
            }
            name()
        }else{
            function name() {
            $('.pricing__cotainer__scroll2').addClass('pricing__cotainer__scroll').removeClass('pricing__cotainer__scroll2');
            }
            name()
        }
    },[hamshow])
    const data2 = [
        {id:1,title:"Server",status1:false,status2:false,status3:false,active:true},
        {id:2,title:"On-Clound / On-Premise",status1:true,status2:true,status3:true},
        {id:3,title:"Data",status1:false,status2:false,status3:false,active:true},
        {id:4,title:"สถานะเครื่องจักรแบบ Real-Time",status1:true,status2:true,status3:true},
        {id:5,title:"สถานะการผลิตแบบ Real-Time",status1:false,status2:true,status3:true},
        {id:6,title:"ประสิทธิภาพของเครื่องจักร(OEE) แบบ Real-Time",status1:false,status2:false,status3:true},
        {id:7,title:"Visualization",status1:false,status2:false,status3:false,active:true},
        {id:8,title:"เห็นสถานะของเครื่องจักรในรูปแบบแผนผังโรงงาน",status1:false,status2:true,status3:true},
        {id:9,title:"เห็นเหตุการณ์ทั้งหมดที่เกิดขึ้นกับเครื่องจักรในรูปแบบ Timeline",status1:true,status2:true,status3:true},
        {id:10,title:"มุมมองการแสดงผลบนหน้าจอขนาดใหญ่ เช่น Smart TV เหมาะสำหรับพื้นที่การผลิต เพื่อกระตุ้นหรือสร้างแรงบัลดาลใจให้พนักงาน",status1:true,status2:true,status3:true},
        {id:11,title:"สรุปข้อมูลออกมาในรูปแบบกราฟ",status1:true,status2:true,status3:true},
        {id:12,title:"รายงานต่างๆ",status1:true,status2:true,status3:true},
        {id:13,title:"Other",status1:false,status2:false,status3:false,active:true},
        {id:14,title:"Export ข้อมูลในรูปแบบไฟล์Excel",status1:true,status2:true,status3:true},
        {id:15,title:"ฟังก์ชั่นสำหรับ Import แผนการผลิตจากExcel",status1:false,status2:true,status3:true},
        {id:16,title:"การจัดการผู้ใช้",status1:true,status2:true,status3:true},
        {id:17,title:"ตั้งค่าปฏิทินวันหยุดและเวลาพักเบรค",status1:true,status2:true,status3:true},
        {id:18,title:"รองรับการทำงานแบบแบ่งกะ(มาตรฐาน 2 กะ)",status1:true,status2:true,status3:true},
        {id:19,title:"รองรับการนับชิ้นงานดีจากเครื่องจักร",status1:false,status2:true,status3:true},
        {id:20,title:"รองรับการนับชิ้นงานเสียจากเครื่องจักรหรือ Manual",status1:false,status2:false,status3:true},
        {id:21,title:"Add-on",status1:false,status2:false,status3:false,active:true},
        {id:22,title:"การแลกเปลี่ยนข้อมูลกับระบบ ERP",status1:false,status2:false,status3:false},
        {id:23,title:"แจ้งเตือนผ่าน Smart Watch (ฟีเจอร์สำหรับ Cloud เท่านั้น)",status1:false,status2:false,status3:false},
        {id:24,title:"แจ้งเตือนผ่าน Line (ฟีเจอร์สำหรับ Cloud เท่านั้น)",status1:false,status2:false,status3:false}
    ]
    const table = data2.map(e=>{
        return(
            <tr key={e.id} style={{fontFamily:"Sarabun"}}>
                <td style={{textAlign:'left',backgroundColor:e.active&& '#f2f2f2',pointerEvents:e.active&&'none'}} className="pricing__texttextetx"><h1 className="table__text__55">{e.title}</h1></td>
                <td  style={{textAlign:'center',backgroundColor:e.active&& '#f2f2f2',pointerEvents:e.active&&'none'}} className="pricing__texttextetx">{e.status1 && <h className="table__text__56">✓</h>}</td>
                <td  style={{textAlign:'center',backgroundColor:e.active&& '#f2f2f2',pointerEvents:e.active&&'none'}} className="pricing__texttextetx">{e.status2 && <h className="table__text__56">✓</h>}</td>
                <td  style={{textAlign:'center',backgroundColor:e.active&& '#f2f2f2',pointerEvents:e.active&&'none'}} className="pricing__texttextetx">{e.status3 && <h className="table__text__56">✓</h>}</td>
            </tr>
        )
    })
    return (
        <div className="pricing__main">
            <div className="pricing__header">
                <Header  />
                <HambergaerMenu togglehamberger={setHamshow}/>
            </div>
            <div className="pricing__cotainer__scroll" style={{overflowX:'hidden'}}>
                <div className="pricing__header_text">
                    <div>
                        <h1>ชุด Demo SEESET</h1>
                        <h2>
                        คุณสามารถติดต่อเราเพื่อขอ Access key ทางเราจะจัดส่งลิงค์ พร้อมรหัสผ่านไปให้ท่าน<br/> เพื่อให้ท่านทดลองและเรียนรู้ฟีเจอร์และฟังก์ชั่นทั้งหมดที่มีใน SEESET
                        </h2>
                    </div>
                </div>
                <div className="Pricing__buttom">
                    <button class="button_pricing button_pricing1"><a rel="noreferrer" href="https://outlook.office365.com/owa/calendar/ZenalyseCoLtd@zenalyse.co.th/bookings/" target="_blank" className="text-link">ติดต่อเรา</a></button>
                </div>
                <div className="pricing__table__container">
                    <table id="customers">
                        <tr>
                            <th style={{pointerEvents:'none'}}></th>
                            <th style={{pointerEvents:'none'}}>SEE2S</th>
                            <th style={{pointerEvents:'none'}}>SEE2E</th>
                            <th style={{pointerEvents:'none'}}>SEE2I</th>
                        </tr>
                        {table}
                    </table>
                </div>
                <footer>
                    <h1>***   หมายเหตุ</h1>
                    <h2>ไม่รวมการค้นหาสัญญาณจากเครื่องจักร ลูกค้าต้องเตรียมสัญญาณจากเครื่องจักรไว้ให้ กรณีต้องการให้หาสัญญาณจากเครื่องจักรให้จะมีค่าใช้จ่ายเพิ่มเติม</h2>
                </footer>
            </div>
        </div>
    )
}

export default Pricing
