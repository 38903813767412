import React,{useState,useEffect} from 'react'
import Header from './Header'
import  '../assets/css/product.css'


//effect animation
import {Fade} from 'react-reveal';

//matrail UI


// image
// section1
import section1 from '../assets/img/product/Card_3D_2.png'

//section2
import People_FactoryOwner from '../assets/img/product/People_FactoryOwner.png'
import People_Maintanace from '../assets/img/product/People_Maintanace.png'
import People_Operator from '../assets/img/product/People_Operator.png'
import People_Supervisor from '../assets/img/product/People_Supervisor.png'

//section3
import HardwareOverall from '../assets/img/product/HardwareOverall.png'

//section4
import Liveboard_All from '../assets/img/product/Liveboard_All.png'

//section5

import List_Illustrator from '../assets/img/product/List_Illustrator.png'
import OverALL from '../assets/img/product/OverALL.png'

//picture section 7 customer
import pic1 from '../assets/img/product/LogoCustomer/CusLogo_Alpine.png'
import pic2 from '../assets/img/product/LogoCustomer/CusLogo_Ampas.png'
import pic3 from '../assets/img/product/LogoCustomer/CusLogo_BankofThailand.png'
import pic4 from '../assets/img/product/LogoCustomer/CusLogo_Continental.png'
import pic5 from '../assets/img/product/LogoCustomer/CusLogo_Exedy.png'
import pic6 from '../assets/img/product/LogoCustomer/CusLogo_Fufifilm.png'
import pic7 from '../assets/img/product/LogoCustomer/CusLogo_Hitachi.png'
import pic8 from '../assets/img/product/LogoCustomer/CusLogo_IRC.png'
import pic9 from '../assets/img/product/LogoCustomer/CusLogo_Kosei.png'
import pic10 from '../assets/img/product/LogoCustomer/CusLogo_MitsuiSiam.png'
import pic11 from '../assets/img/product/LogoCustomer/CusLogo_Nachi.png'
import pic12 from '../assets/img/product/LogoCustomer/CusLogo_Nissin.png'
import pic13 from '../assets/img/product/LogoCustomer/CusLogo_Plasses.png'
import pic14 from '../assets/img/product/LogoCustomer/CusLogo_Rohm.png'
import pic15 from '../assets/img/product/LogoCustomer/CusLogo_Shinetsu.png'
import pic16 from '../assets/img/product/LogoCustomer/CusLogo_Toshiba.png'
import pic17 from '../assets/img/product/LogoCustomer/CusLogo_ToyotaBoshoku.png'
import pic18 from '../assets/img/product/LogoCustomer/CusLogo_Tsubaki.png'
import pic19 from '../assets/img/product/LogoCustomer/CusLogo_YSP.png'


//picture section 7 partner
import partner1 from '../assets/img/product/LogoPartner/Aiden.png'
import partner2 from '../assets/img/product/LogoPartner/KPT.png'
import partner3 from '../assets/img/product/LogoPartner/Maxwell_Innovation.png'
import partner4 from '../assets/img/product/LogoPartner/Nec.png'
import partner5 from '../assets/img/product/LogoPartner/Netmarks.png'
import partner6 from '../assets/img/product/LogoPartner/SIEPLUS.png'
import partner7 from '../assets/img/product/LogoPartner/TKKCorp.png'
import partner8 from '../assets/img/product/LogoPartner/Ueno.png'
import partner9 from '../assets/img/product/LogoPartner/VSTECS.png'
import partner10 from '../assets/img/product/LogoPartner/Yamazen.png'
import partner11 from '../assets/img/product/LogoPartner/PartnerLogo_Patlite.png'


//slide picture Carousel
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import HambergaerMenu from './HambergaerMenu';
import $ from 'jquery';
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1025 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 481 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 2
    }
  };

function Product() {
    const [hamshow , setHamshow ] = useState(false)
    useEffect(()=>{
        if(hamshow){
        function name() {
            $('.product__cotainer__scroll').addClass('product__cotainer__scroll2').removeClass('product__cotainer__scroll');
        }
        name()
        }else{
        function name() {
            $('.product__cotainer__scroll2').addClass('product__cotainer__scroll').removeClass('product__cotainer__scroll2');
        }
        name()
        }
    },[hamshow])
    return (
        <div className="product_container_product" >
            <div className="product_container__header_fefefef">
                <Header />
                <HambergaerMenu togglehamberger={setHamshow}/>
            </div>
            <div className="product__cotainer__scroll" style={{overflowX:'hidden'}}>
                <div className="product_section1__container">
                    <Fade left  duration={2000} >
                        <div className="product_section1__text-container">
                            <h1 className="product_section1__text-header">เพิ่มประสิทธิภาพการผลิตสูงสุด</h1>
                            <div className="product_section1__dummy"/>
                            <span  className="product_section1__text-sub-header">
                                <h1>ลดความสูญเสียให้เกิดขึ้นน้อยที่สุด</h1>
                            </span>
                            <div className="product_section1__dummy"/>

                            <h1 className="product_section1__detail">
                            SEESET คือ ระบบ IIOT ที่มีวัตถุประสงค์ในการติดตามสถานะการทำงานของเครื่องจักร เพื่อนำข้อมูลที่ได้มาพัฒนากระบวนการผลิตให้เครื่องจักรสามารถผลิตได้เต็มประสิทธิภาพที่สุด
                            </h1>
                            <div className="product_section1__dummy"/>
                            <h1 className="product_section1__detail">
                            ระบบแสดงผลแบบ Real-Time พร้อมบันทึกข้อมูลสร้าง report ให้โดยอัตโนมัติตลอด 24 ชั่วโมง และสามารถดูข้อมูลย้อนหลังได้อย่างไม่จำกัดพร้อมทั้งสามารถบันทึกสาเหตุต่าง ๆ ที่ทำให้เครื่องจักรหยุดทำงานและของเสียที่เกิดขึ้นจากการผลิตเพื่อนำมาวิเคราะห์ปรับปรุงกระบวนการผลิตให้เกิดความสูญเสียน้อยที่สุด
                            </h1>
                        </div>
                    </Fade >
                    <div className="product_section1__dummy"/>

                    <Fade right  duration={2000} >
                    <div className="product_section1__picture-container">
                        <img src={section1} className="product_section1__picture" alt="" />
                    </div>
                    </Fade>
                </div>



                <div className="product_section2__container">
                    <div className="product_section2__text-header-container">
                        <Fade down  duration={2000} >
                        <span className="product_section2__text-header">
                            <h1>
                                โซลูชั่นนี้เหมาะกับใคร ?
                            </h1>
                        </span>
                        </Fade>
                    </div>
                    <div className="product_section2__container1">
                        <Fade left  duration={2000} >
                        <div className="product_section2__container1-left">
                            <img src={People_FactoryOwner} className="product_section2__image1" alt=""/>
                            <div className="product_section2__container-text">
                                <h1 className="product_section4__header-right">
                                    ผู้จัดการโรงงาน
                                </h1>
                                <div className="product_dummy__text2-1"/>
                                <h1 className="product_section2__container1-left-text">
                                สามารถดูรายงานผลการดำเนินงาน และผลการผลิตได้ทันที ทั้งแบบ Real-Time และแบบสรุปรายวัน สามารถดูข้อมูลย้อนหลังได้ตั้งแต่วันแรกที่ติดตั้งระบบได้ ไม่มีวันสิ้นสุด 
                                อีกทั้งยังเสริมภาพลักษณ์ให้กับตัวบริษัทเองให้ดูมีความทันสมัย ดูเหมาะกับการเข้าสู่โรงงานยุค Industry 4.0
                                </h1>
                            </div>
                        </div>
                        </Fade>

                        <Fade right  duration={2000} >
                        <div className="product_section2__container1-right">
                            <img src={People_Maintanace} className="product_section2__image1" alt=""/>
                            <div className="product_section2__container-text">
                                <h1 className="product_section4__header-left" style={{marginTop:-7}}>
                                    ผู้จัดการฝ่าย Maintenance
                                </h1>
                                <div className="product_dummy__text2-1"/>
                                <h1 className="product_section2__container1-right-text">
                                ข้อมูลที่ได้จากระบบ เป็นข้อมูลที่แม่นยำ 100% ไม่สามารถแก้ไขได้ ทำให้ล่วงรู้ได้ว่า เครื่องใดที่เริ่มมีสัญญาณของปัญหา เกิด Alarm บ่อย หรือใช้เวลาในการ Set up นาน ซึ่งเหตุการณ์เหล่านี้ล้วนส่งผลกระทบต่อภาพรวมการผลิต รวมไปถึง MTTR ระยะเวลาในการล่วงรู้ถึงปัญหา และการเข้าไปซ่อมแซมหน้างานจะสามารถทำได้รวดเร็วขึ้น พร้อมทั้งเก็บ Log ข้อมูลย้อนหลังไว้เพื่อวิเคราะห์ได้ตลอดเวลา
                                </h1>
                            </div>
                        </div>
                        </Fade>
                    </div>



                    <div className="product_section2__container2">
                        <Fade left  duration={2000} >
                        <div className="product_section2__container1-left">
                            <img src={People_Operator} className="product_section2__image1" alt=""/>
                            <div className="product_section2__container-text" >
                                <h1 className="product_section4__header-right" >
                                    พนักงานหน้างาน
                                </h1>
                                <div className="product_dummy__text2-1"/>
                                <h1 className="product_section2__container1-left-text ">
                                    SEESET จะช่วยสรุปสัญญาณ เหตุการณ์ ศักยภาพในการผลิต สาเหตุของการเกิด Loss cause ต่างๆ การบันทึกของเสีย ฉะนั้น การบันทึกลงบนกระดาษจะหมดไป การต้องมานั่งกังวลว่าจะพิมพ์ข้อมูลผิด 
                                    หรือถูกจะไม่เกิดขึ้นอีก เพราะ SEESET คำนวณมาให้หมดแล้ว รวมไปถึง OEE ที่เป็น เกณฑ์วัดมาตรฐานที่ทั่วโลกยอมรับ
                                </h1>
                            </div>
                        </div>
                        </Fade>

                        <Fade right  duration={2000} >
                        <div className="product_section2__container1-right">
                            <img src={People_Supervisor} className="product_section2__image1" alt=""/>
                            <div className="product_section2__container-text">
                                <h1 className="product_section4__header-left text-right text__mobile-left">
                                    ผู้จัดการฝ่าย Production
                                </h1>
                                <div className="product_dummy__text2-1"/>
                                <h1 className="product_section2__container1-right-text"  >
                                    หมดปัญหาเรื่องต้องนับชิ้นงาน ทีละชิ้นอีกต่อไป Hardware ที่ประกอบในระบบ SEESET มีฟังก์ชั่นที่สามารถนับชิ้นงานได้ รวมไปถึงวัด Performance หรือ Speed ของการผลิตชิ้นงานแต่ละชิ้นได้ ทำให้สามารถวิเคราะห์ได้ว่า 
                                    เครื่องจักรที่ทำงานสมบูรณ์ 100% จะต้องผลิตได้ด้วยเวลาที่คงที่ ตรงเวลา หากเกิดปัญหาขึ้น สามารถประสานงานไปยังฝ่าย Maintenance ได้อย่างทันท่วงที
                                </h1>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>

                
                <div className="product_section3__container">
                    <Fade left  duration={2000} >
                    <div className="product_section3__text">
                        <h1 className="product_section3_text1">การทำงานของ SEESET</h1>
                        <h1 className="product_section3_text2">
                        SEESET จะทำการดึงข้อมูลสถานะของเครื่องจักรจากเสา Tower light เพื่อนำมาเก็บบันทึก 
                        คำนวณ วิเคราะห์ และสรุปเป็นรายงาน เพื่อเก็บข้อมูลและพัฒนาการผลิต</h1>
                    </div>
                    </Fade>
                    <Fade right  duration={2000} >
                    <div className="product_section3__piture-container">
                        <img src={HardwareOverall} className="product_section3__piture" alt=""/>
                    </div>
                    </Fade>
                </div>


                <div className="product_section4__container">
                    <Fade down  duration={2000} >
                    <div className="product_section4__container-top">
                        <h1 className="product_section4__header">ย่อทั้งโรงงานให้อยู่ในมือคุณ</h1>
                        <div className="product_dummy__scrion4"/>
                        <h1 className="product_section4__text-detail">
                        SEESET จะอำนวยความสะดวกให้กับผู้ใช้งาน โดยสามารถใช้งานผ่านอุปกรณ์ใดๆ ก็ได้ที่สามารถ <br/>
                        เปิด Web browser และมี Internet ฉะนั้น ไม่ว่าคุณจะอยู่ที่ไหนก็ตาม ก็สามารถติดตามการ  <br/>
                        ดำเนินการงานโรงงานคุณได้ตลอด
                        </h1>
                    </div>
                    </Fade>
                </div>

                <div className="product_section5__container">
                    <div className="product_section5__top-container">
                        <Fade left  duration={2000} >
                        <div className="product_section5__right-text">
                            <h1 className="product_section4__header-left">
                                ติดตามการผลิตแบบ Real-Time
                            </h1>
                            <div className="product_dummy__section5"/>
                            <h1 className="product_section4__text-detail">
                                คุณสามารถสำรวจสถานะของเครื่องจักร ณ ปัจจุบันได้ว่าอยู่ในสถานะใด เกิดสถานะนี้มานานแค่ไหน รวมถึงยอดการผลิตที่ตั้งเป้าไว้ในแผน
                                ยอดการผลิตที่ควรจะได้จริง ณ เวลานี้และผลผลิตที่เกิดขึ้นจริง รวมถึง Cycle ตาม ระยะเวลาในการผลิตต่อชิ้น และท้ายสุดช่อง Diff ตัวเลขความต่างระหว่างจำนวนที่ควรจะผลิตได้ 
                                กับจำนวนที่ผลิตได้จริง หากเป็นตัวสีแดงแสดงว่าผลิตได้ช้ากว่าที่ควรจะเป็น ถ้าเป็นสีขาวหมายความว่า ผลิตได้เร็วและดีกว่าที่กำหนด
                            </h1>
                        </div>
                        </Fade>

                        <Fade right  duration={2000} >
                        <div className="product_section5__container-picer">
                            <img src={Liveboard_All} alt="" className="product_section5__picture"/>
                        </div>
                        </Fade>
                    </div>
                    <div className="product_section5__bottom-container">
                        <Fade left  duration={2000} >
                        <div className="product_section5__container-picer">
                            <img src={List_Illustrator} alt="" className="product_section5__picture"/>
                        </div>
                        </Fade>

                        <Fade right  duration={2000} >
                        <div className="product_section5__left-text">
                            <h1 className="product_section4__header-left">
                                ทุกความคืบหน้าของการผลิต
                            </h1>
                            <div className="product_dummy__section5"/>
                            <h1 className="product_section4__text-detail">
                                คุณสามารถสำรวจสถานะของเครื่องจักร ณ ปัจจุบันได้ว่า อยู่ในสถานะใด เกิดสถานะนี้มานานแค่ไหน 
                                รวมถึงยอดการผลิต ที่ตั้งเป้าไว้ในแผน ยอดการผลิตที่ควรจะได้จริง ณ เวลานี้ และผลผลิตที่เกิดขึ้นจริง 
                                รวมถึง Cycle time ตาม ระยะเวลาในการผลิตต่อชิ้น และท้ายสุด ช่อง Diff คือตัวเลขความต่างระหว่าง 
                                จำนวนที่ควรจะผลิตได้ กับจำนวนที่ผลิตได้จริง หากเป็นตัวสีแดง แสดงว่าผลิตได้ช้ากว่าที่ควรจะเป็น ถ้าเป็นสีขาว หมายถึง ผลิตได้เร็วและดีกว่าที่กำหนด
                            </h1>
                        </div>
                        </Fade>
                        
                    </div>
                    <div className="product_section5__top-container">
                        <Fade left  duration={2000} >
                        <div className="product_section5__right-text">
                            <h1 className="product_section4__header-left">
                                ภาพรวมการผลิตเครื่องจักรตลอดวัน
                            </h1>
                            <div className="product_dummy__section5"/>
                            <h1 className="product_section4__text-detail">
                                ฟังก์ชั่นนี้ เหมาะสำหรับการทำสรุป Report เพื่อให้ผู้บริหารดูภายในฟังก์ชั่นจะประกอบไปด้วย รายละเอียดของการผลิตเชิงลึกทั้งหมด ผลิตชิ้นส่วนอะไรไปบ้าง 
                                ใครเป็นผู้จัดการเครื่องนั้นๆ Loss ต่างๆเกิดขึ้นจากสาเหตุอะไรบ้าง คิดเป็นระยะเวลากี่นาที กี่วินาที และสรุป กราฟ OEE ของเครื่องนั้น ในวันนั้น 
                                มาให้ด้วย โดยกราฟนี้ จะสรุป เมื่อสิ้นสุดของการผลิตเครื่องนั้น ของวันนั้น เช่น เริ่มทำงาน 8:00 โมง เลิกงาน 17:00 กราฟจะถูกสรุปมาหลังจาก 17:00
                            </h1>
                        </div>
                        </Fade>

                        <Fade right  duration={2000} >
                        <div className="product_section5__container-picer">
                            <img src={OverALL} alt="" className="product_section5__picture"/>
                        </div>
                        </Fade>
                    </div>
                </div>



                <div className="product_section6__container">
                    <Fade down  duration={2000} >
                    <div className="product_section6__top-container">
                        <h1 className="product_section6__font-header">รายงาน</h1>
                        <div className="product_dummy__section6"/>
                        <h1 className="product_section6__text-detail">
                            ข้อมูลและเหตุการณ์ต่างๆที่เกิดขึ้น ระบบจะทำการบันทึกอัตโนมัติ ให้ผู้ใช้งานสามารถเรียกดูข้อมูลย้อนหลังได้ตลอดเวลาในระบบ <br/>
                            หรือต้องการนำข้อมูลไปใช้งานอย่างอื่นต่อก็สามารถ Export ออกไปในรูปแบบของกราฟสรุปด้วย CSV file หรือไฟล์ข้อมูลที่เป็น Excel file<br/>
                        </h1>
                    </div>
                    </Fade>

                    
                    <div className="product_section6__bottom-container">
                        
                        <div className="product_section6__container1">
                            <Fade left  duration={2000} >
                            <div className="product_section6__container2">
                                <h1 className="product_section6__font-header">Status Report</h1>
                                <div className="product_dummy__section6"/>
                                <h1 className="product_section6__text-detail">รายงานสถานการณ์ของเครื่องจักรที่เกิดขึ้นในช่วงเวลาหลากหลายรูปแบบ ไม่ว่าจะเป็นแบบรายชั่วโมง รายวัน รายกะการทำงาน รายอาทิตย์ รายเดือน</h1>
                            </div>
                            </Fade>
                            <div className="product__section6__dummy"/>
                            <Fade right  duration={2000} >
                            <div className="product_section6__container2">
                                <h1 className="product_section6__font-header">Losstime Report</h1>
                                <h1 className="product_section6__text-detail">รายงานเวลาสูญเสียที่เกิดขึ้น จะช่วยบอกคุณว่าแต่ละเครื่องจักรเกิดเหตุขัดข้องอะไร สูญเสียเวลาไปกับเหตุการณ์นี้มากน้อยแค่ไหน</h1>
                            </div>
                            </Fade>
                        </div>
                        
                        <div className="product_section6__container1">
                            <Fade left  duration={2000} >
                            <div className="product_section6__container2">
                                <h1 className="product_section6__font-header">Production Report</h1>
                                <div className="product_dummy__section6"/>
                                <h1 className="product_section6__text-detail">รายงานการผลิตของเครื่องจักรที่จะทำให้คุณได้รู้ เครื่องจักรของคุณผลิตอะไรบ้าง ใช้เวลาเท่าไหร่ เหตุการณ์ตอนที่ผลิตเป็นยังไง</h1>
                            </div>
                            </Fade>
                            <div className="product__section6__dummy"/>
                            <Fade right  duration={2000} >
                            <div className="product_section6__container2">
                                <h1 className="product_section6__font-header">Losstime Report by Machine</h1>
                                <div className="product_dummy__section6"/>
                                <h1 className="product_section6__text-detail">ภาพรวมเวลาสูญเสียของแต่ละเครื่องจักรที่ถูกแสดงผลในรูปแบบของกราฟ ซึ่งสามารถเปรียบเทียบความผิดปกติของแต่ละเครื่องจักรได้อย่างชัดเจน</h1>
                            </div>
                            </Fade>
                        </div>
                        <div className="product_section6__container1">
                            <Fade up  duration={2000} >
                            <div className="product_section6__container2 ">
                                <h1 className="product_section6__font-header">Losstime Report by Reason</h1>
                                <div className="product_dummy__section6"/>
                                <h1 className="product_section6__text-detail">รายงานสรุปรวมการสูญเสียเวลา เหตุผลใดที่เกิดขึ้นมากที่สุด สูญเสียเวลาไปกับเหตุผลนั้นเท่าไหร่ และมีเหตุผลอะไรอีกรองตามลงมาที่ทำให้เกิดการสูญเสีย</h1>
                            </div>
                            </Fade>
                        </div>
                    </div>
                </div>


                <div className="product_section7__container" >
                    <div className="product_section7__container1">
                        <div className="product_section7_text_container1">
                            <h1 className="product_section7__text-header">
                                ปรึกษาฟรี
                            </h1>
                            <h1 className="product_section7__text">
                                Zenalyse ยินดีให้คำปรึกษา Site Survey หรือให้คำแนะนำ สำหรับผู้ที่อยากเริ่มต้นวางระบบ
                                Real-Time Machine Monitoring ฟรี โดยไม่มีค่าจ่ายใดๆ เพียงนัดหมายกับทางทีมงานของเรา เราพร้อมช่วยเหลือคุณเสมอ
                            </h1>
                            <div className="product_section7__dummy" />
                            {/* <button class="button_product button_product1"><Link to="/contact">ติดต่อเรา</Link></button> */}
                            <button class="button_usecase button_usecase1"><a rel="noreferrer" href="https://outlook.office365.com/owa/calendar/ZenalyseCoLtd@zenalyse.co.th/bookings/" target="_blank" className="text-link">ติดต่อเรา</a></button>
                        </div>
                    </div>

                    <div className="product_section7__container2">
                        <div className="product_section7__title">
                            <span><h1>Profile</h1></span>
                        </div>
                        <div className="product_section7__customer">
                        <Carousel responsive={responsive} autoPlay={true} infinite={true} autoPlaySpeed={1000} arrows={false} itemClass="carousel-item-padding-40-px">
                            {[pic1,pic2,pic3,pic4,pic5,pic6,pic7,pic8,pic9,pic10,pic11,pic12,pic13,pic14,pic15,pic16,pic17,pic18,pic19].map(e=>(

                                <div key={e.toString()}>
                                    <img src={e} alt="" style={{width:"90%",height:'auto'}}/>
                                </div>
                            ))}
                        </Carousel>
                        </div>
                    </div>
                    <div className="product_section7__container3">
                        <div className="product_section7__titile">
                            <span><h1 >Partner</h1></span>
                        </div>
                        <div className="pruduct_section7__logoAll">
                            <div className="gridLoGO">
                                <div className="product_section7_item1">
                                    <img src={partner1} alt=""/>
                                </div>
                                <div className="product_section7_item2">
                                    <img src={partner2} alt=""/>
                                </div>
                                <div className="product_section7_item3">
                                    <img src={partner3} alt=""/>
                                </div>
                                <div className="product_section7_item4">
                                    <img src={partner4} alt=""/>
                                </div>
                                <div className="product_section7_item5">
                                    <img src={partner5} alt=""/>
                                </div>
                                <div className="product_section7_item6">
                                    <img src={partner6} alt=""/>
                                </div>
                                <div className="product_section7_item7">
                                    <img src={partner7} alt=""/>
                                </div>
                                <div className="product_section7_item8">
                                    <img src={partner8} alt=""/>
                                </div>
                                <div className="product_section7_item9">
                                    <img src={partner9} alt=""/>
                                </div>
                                <div className="product_section7_item10">
                                    <img src={partner10} alt=""/>
                                </div>
                                <div className="product_section7_item11">
                                    <img src={partner11} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Product
