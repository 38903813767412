import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home2 from "./components/Home2.jsx";
import Usecase from "./components/Usecase";
import Product from "./components/Product";
import Contact from './components/Contact'
import Pricing from "./components/Pricing";
export default function App() {
  return (
      <Router>
          <Switch>
            <Route path="/" exact>
              <Home2 />
            </Route>
            <Route path="/home" exact>
              <Home2 />
            </Route>
            <Route path="/usecase" exact>
              <Usecase />
            </Route>
            <Route path="/product" exact>
              <Product />
            </Route>
            <Route path="/contact" exact>
              <Contact />
            </Route>
            <Route path="/pricing" exact>
              <Pricing />
            </Route>
          </Switch>
      </Router>
  );
}

