import React, { useState,useEffect} from "react";
import GoogleMapReact from "google-map-react";
import '../assets/scss/contact.css'
import Header from '../components/Header'
import HambergaerMenu from './HambergaerMenu'
import $ from 'jquery';
import axios from 'axios'
import '../assets/css/contact2.css'
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
const Contact = () => {
  const [hamshow , setHamshow ] = useState(false)
  const [name , setName ] = useState('')
  const [email , setEmail ] = useState('')
  const [detail , setDetail ] = useState('')
  
  const [open, setOpen] = useState(false);

  useEffect(()=>{
    if(hamshow){
      function name() {
        $('.contact__cotainer__scroll').addClass('contact__cotainer__scroll2').removeClass('contact__cotainer__scroll');
      }
      name()
    }else{
      function name() {
        $('.contact__cotainer__scroll2').addClass('contact__cotainer__scroll').removeClass('contact__cotainer__scroll2');
      }
      name()
    }
  },[hamshow])

  const recievData =(e)=> {
    setOpen(true)
    e.preventDefault()
    // setOpen(true)
    console.log("email = ",email,"name =", name , "detail = ",detail)
      const datasent = {
        email:email,
        name:name,
        detail:detail
      }
      axios.post('/mail/',datasent)
    .then(function (response) {
      // handle success
      setOpen(false)
      console.log(response.data);
    })
    .catch(function (error) {
      // handle error
      setOpen(false)
      console.log(error);
    })
  }




  const props = {
    center: {
      lat: 13.895592228709159,
      lng: 100.65680773264573,
    },
    zoom: 16,
  };
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: 13.895592228709159, lng: 100.65680773264573 },
      map,
      title: "zenalyse",
    });
    return marker;
  };
  return (
    <div className="contact__main_container">
        <div className="contact_container__header" >
            <Header />
            <HambergaerMenu togglehamberger={setHamshow}/>
        </div>
        <div className="contact__cotainer__scroll" style={{overflowX:'hidden'}}>
            <div className="googlemap">
            <iframe className="googlemap-iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15492.225253214627!2d100.656841!3d13.895581!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x91705fa0b70f9986!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4i-C4teC4meC4suC5hOC4peC4qiDguIjguLPguIHguLHguJQ!5e0!3m2!1sth!2sth!4v1673595609621!5m2!1sth!2sth" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            {/* <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyBG1aTe3YmusIn0qHzp_dI30zZwZ15rGSE" }}
              defaultCenter={props.center}
              defaultZoom={props.zoom}
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            >
            </GoogleMapReact> */}
          </div>
          <div className="contact__boxMessage">
            <h1 >Zenalyse Company Limited</h1>
            <p>เวลาทำการ วันจันทร์ – วันศุกร์ 08:30 – 17:30 น.</p>
          </div>
          <div className="container__grid">
            <div className="section1">
              <h1>สำนักงาน</h1>
              <p>555/94 B-Avenue ถ.สุขาภิบาล5 แขวงออเงิน เขตสายไหม กรุงเทพมหานคร 10220</p>
            </div>
            <div className="section2">
              <h1>อีเมล</h1>
              <p>sales@zenalyse.co.th</p>
            </div>
            <div className="section3">
              <h1>ศูนย์บริการลูกค้า</h1>
              <p>02-1587338-9</p>
            </div>
          </div>

          <div className="section4__contact">
              <h1 >ส่งข้อความหาเราทันที</h1>
                <form onSubmit={recievData} className="contact__main">
                  <input type="text"  name="firstname" placeholder="ชื่อ-นามสกุล" className="contact__input" onChange={(e)=>setName(e.target.value)}></input> <br /><br /><br />
                  <input type="text"  name="lastname" placeholder="อีเมลล์ในการติดต่อกลับ" className="contact__input" onChange={(e)=>setEmail(e.target.value)}></input><br /><br /><br />
                  <textarea name="detail" cols="60" rows="5" className="contact__textarea" placeholder="มีอะไรให้เราช่วยมั้ยคะ ?" onChange={(e)=>setDetail(e.target.value)}></textarea><br /><br /><br />
                  <input type="submit" value="Submit" className="contact__buttom"></input>
                  <div style={{marginLeft:'48%',marginTop:20}}>
                    {open && <Spinner />} 
                  </div>
                </form>
          </div>
        </div>
    </div>
  );
};

export default Contact;

