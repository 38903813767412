import React from 'react'
import picture from '../assets/img/seeset_green_black.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import '../assets/scss/header.css'

const Header = () => {
    return (
        <div className="container__main">
            <div className="dummy"/>
            <div className='box-telphone'>
                <FontAwesomeIcon className='icon_phone' icon={faPhone}></FontAwesomeIcon>
                <a className='text-font-tel' href="tel:02-1587-338">02-1587-338</a>
            </div>
            <Link to="/home" className="text-link"><img src={picture} alt="" /></Link>
            <Link to="/product" className="text-link"><h1>รายละเอียดผลิตภัณฑ์</h1></Link>
            <Link to="/usecase" className="text-link"><h1>กรณีศึกษา</h1></Link>
            <Link to="/pricing" className="text-link"><h1>แพ็กเกจของเรา</h1></Link>
            <Link to="/contact" className="text-link"><h1>ติดต่อเรา</h1></Link>
            <a href="https://pistil-seeset.zenalyse.com/Account/Login" rel="noreferrer" target="_blank" className="text-link">
                <div >
                    <h1>Live Demo</h1>  
                </div>
            </a>
            <div className="dummy"/>
        </div>
    )
}

export default Header
