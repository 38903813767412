import React, { useEffect, useState } from "react";

import Header from "./Header";
import '../assets/css/home.css'
import {Fade} from 'react-reveal';
import Sticky from 'react-stickynode';
import videoSrc from "../assets/img/home/Move_Layout.mp4";
import Layout_info from "../assets/img/home/Layout_info.png";
import ListStatus from "../assets/img/home/ListStatus.png";
import ListProgress from "../assets/img/home/ListProgress.png";
import ListOEE from "../assets/img/home/ListOEE.png";
import Move_Timeline from "../assets/img/home/Move_Timeline.mp4";
import Report_Losstime_by_Reason from "../assets/img/home/Report_Losstime_by_Reason.png";
import Report_Daily from "../assets/img/home/Report_Daily.png";
import Towerlight_text from "../assets/img/home/Towerlight_text.png";
import Install_Towerlight from "../assets/img/home/Install_Towerlight.mp4";
import Server_aws from "../assets/img/home/Server_aws.png";
import Server_OnPermise from "../assets/img/home/Server_OnPermise.png";
import MockupCardView from "../assets/img/home/MockupCardView.png";
import factory from "../assets/img/home/Factory.png";
import HambergaerMenu from "./HambergaerMenu";
import $ from 'jquery';
const Home2 = () => {
  const [hamshow , setHamshow ] = useState(false)
  useEffect(()=>{
    if(hamshow){
      function name() {
        $('.home2__cotainer__scroll').addClass('home2__cotainer__scroll2').removeClass('home2__cotainer__scroll');
      }
      name()
    }else{
      function name() {
        $('.home2__cotainer__scroll2').addClass('home2__cotainer__scroll').removeClass('home2__cotainer__scroll2');
      }
      name()
    }
  },[hamshow])
 
  useEffect(()=>{
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){dataLayer.push(arguments);}
    // gtag('js', new Date());
    // gtag('config', 'UA-52164800-2');
  },[])


  return (
    <div className="container_home">
        <div className="container__header2" >
          <Header />
          <HambergaerMenu togglehamberger={setHamshow}/>
        </div>
        <div className="home2__cotainer__scroll" style={{overflowX:'hidden'}}>
          <Sticky top={1} enabled={true}>
          <Fade bottom  duration={2500}>
            <div className="machineLayout__container animated animatedFadeInUp fadeInUp" >
              {/* <img className="machineLayout_image" src={MockupCardView} alt=""/> */}
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/rVBPmP5SYBw?autoplay=1&mute=1&loop=1&controls=0&showinfo=0&playlist=rVBPmP5SYBw&loop=1" title="YouTube video player" frameborder="0" 
                allow="accelerometer; clipboard-write; encrypted-media;" frameborder="0" allowfullscreen></iframe>
              <div className="machineLayout__text-container">   
                  <h1>SEESET</h1>
                  <h1>Industrial IoT 4.0</h1>
                  <h1>Real-time machine monitoring solution</h1>
                  <div onClick={()=>window.open('https://outlook.office365.com/owa/calendar/ZenalyseCoLtd@zenalyse.co.th/bookings/','_blank','noopener')}>
                    <h2>นัดหมาย<br/>ให้เราพรีเซนต์</h2><br />
                    <h1>"ON-LINE"</h1>
                  </div>
              </div>
            </div>
          </Fade>
          </Sticky>

          <Sticky top={2} enabled={true}>
          <div className="factoryLayout__container">
            <Fade left  duration={2500} >
              <div className="factoryLayout__container-text">
                <h1 className="factoryLayout__text">ย่อทั้งโรงงานให้อยู่</h1>
                <h1 className="factoryLayout__text" >ในมือคุณ</h1>
              </div>
            </Fade>
            <Fade right duration={2500} >
              <img className="factoryLayout__factory" src={factory} alt=""/>
            </Fade>
          </div>
          </Sticky>

          <Sticky top={3}  enabled={false}>
            <div className="video-machine-layout__container" >
              <Fade left  duration={2500} >
              <div  className="wrapText" >
                <h1 className="video-machine-layout__font">รู้ทุกตำแหน่งของเครื่องจักร พร้อมสถานะแบบ Real-Time</h1>
              </div>
              </Fade>
              <video className="video-machine-layout__video" autoPlay={true} loop={true} muted={true} src={videoSrc} />
            </div>
          </Sticky>

          <Sticky enabled={true}>
            <div className="report1__container" >
              <Fade left duration={2500} >
                <h1 className="report1__text">เจาะลึกความเคลื่อนไหวของเครื่องจักร</h1>
              </Fade>
              <Fade right  duration={2500} >
                <img className="report1__image" src={Layout_info} alt=""/>
              </Fade>
            </div>
          </Sticky>

          <Sticky top={5} enabled={true}>
            <div className="container_5" >
              <div className="dummy"/>
              <Fade left duration={2500} >
                  <h1 className="container_5__text ">บันทึกทุกสถานะที่เกิดขึ้นตั้งแต่เริ่มทำงาน</h1>
                  <h1 className="container_5__text text2">พร้อมสรุปเวลาที่เครื่องจักรทำงานจริง</h1>
              </Fade>
              <Fade right duration={2500} >
                <img className="container_5_picture" src={ListStatus} alt=""/>
              </Fade>
            </div>
          </Sticky>

          <Sticky top={6} enabled={true}>
            <div className="container_5 " >
              <Fade left duration={2500} >
                <div className="dummy" />
                <h1 className="container_5__text">ติดตามความคืบหน้าของการผลิตอย่างใกล้ชิด</h1>
              </Fade>
              <Fade right duration={2500} >
                <img className="container_5_picture" src={ListProgress} alt=""/>
              </Fade>
            </div>
          </Sticky>

          <Sticky top={7} enabled={true}>
            <div className="container_5 ">
              <Fade left  duration={2500} >
                <div className="dummy" />
                <h1 className="container_5__text">สรุปประสิทธิภาพการทำงานโดยรวมของเครื่องจักร(OEE)</h1>
              </Fade>
              <Fade right duration={2500} >
                <img className="container_5_picture"  src={ListOEE} alt=""/>
              </Fade>
            </div>
          </Sticky>


          <Sticky top={8} enabled={true}>
            <div className="container_6">
              <Fade left  duration={2500} >
                <div className="dummy6" />
                <h1 className="container_6__text">ตรวจสอบสถานการณ์ของเครื่องจักรได้อย่างง่ายดายจากข้อมูลที่น่าเชื่อถือ</h1>
                <div className="dummy6" />
              </Fade>
              <video className="video-machine-layout__video" autoPlay={true} loop={true} muted={true} src={Move_Timeline} />

            </div>
          </Sticky>

          <Sticky top={9} enabled={true}>
            <div className="container_7 "  >
              <Fade left  duration={2500} >
                <div className="dummy" />
                <h1 className="container_7__text">แหล่งรวมที่จะทำให้คุณรู้ถึงสาเหตุที่เครื่องจักรหยุดทำงาน</h1>
              </Fade>
              <div className="dummy5" />
              <Fade right  duration={2500} >
                <img className="container_7__picture" src={Report_Losstime_by_Reason} alt=""/>
              </Fade>
            </div>
          </Sticky>

          <Sticky top={10} enabled={true}>
            <div className="container_8" >
              <Fade up  duration={2500} >
                <div className="dummy" />
                <h1 className="container_8__text-header">ดูสรุปรายงานข้อมูลที่เกิดขึ้นกับเครื่องจักรได้ถึง 5 แบบ</h1>
              </Fade>
              <div className="container__wrap">
                <Fade left  duration={2500} >
                  <div className="dummy" />
                  <div className="container_8_picture">
                    <img className="container_8__img-show" src={Report_Daily} alt=""/>
                  </div>
                </Fade>
                <Fade right  duration={2500} >
                  <div className="container_text">
                    <h1 className="container_8__text-topic">Production Report</h1>
                    <h1 className="container_8__text-detail">รายงานผลการผลิต</h1>
                    <h1 className="container_8__text-topic">Status Report</h1>
                    <h1 className="container_8__text-detail">รายงานสถานะที่ส่งมาจากเครื่องจักรทั้งหมด</h1>
                    <h1 className="container_8__text-topic">Loss time</h1>
                    <h1 className="container_8__text-detail">รายงานสาเหตุที่ทำให้เครื่องหยุดทำงาน พร้อมสรุปจำนวนครั้ง และระยะเวลาที่เกิดขึ้น</h1>
                    <h1 className="container_8__text-topic">Loss time By Machine</h1>
                    <h1 className="container_8__text-detail">รายงานสถิติระยะเวลา ที่เครื่องจักรจักรหยุดทําางานของแต่ละเครื่องพร้อมสาเหตุที่ทําาให้เครื่องหยุด และระยะเวลาของแต่ละสาเหตุในรูปแบบกราฟ</h1>
                    <h1 className="container_8__text-topic">Loss time By Reason</h1>
                    <h1 className="container_8__text-detail">
                    รายงานสถิติสาเหตุที่ทําาให้เครื่องจักรหยุดทําางาน โดยเรียงลําาดับสาเหตุที่เกิดขึ้นสูงสุด ไปยังน้อยสุด พร้อมสรุประยะเวลารวมที่เกิดสาเหตุนั้นๆ ในรูปแบบกราฟ
                    </h1>
                    <div style={{width:50,height:"1vh"}}/>
                    <h1 className="container_8__text-note" >** หมายเหตุ ** ทุกรูปแบบของ Report สามารถเลือก ช่วงระยะเวลาเป็นรายวัน หลายวัน สัปดาห์ เดือน หรือรวมทั้งหมดตั้งแต่ติดตั้งระบบ และยังสามารถเลือกเฉพาะกลุ่มของเครื่องจักร หรือเลือกเฉพาะเครื่องจักรใด เครื่องหนึ่งได้</h1>
                  </div>
                </Fade>
              </div>
            </div>
          </Sticky>

          <Sticky top={9} enabled={true}>
            <div  className="container_9 ">
              <Fade down  duration={2500} >
                <div className="dummy" /> 
                <h1 className="container_9_text-header" >เพียงมี Towerlight ก็สามารถติดตั้งได้อย่างง่ายดาย</h1>
              </Fade>
              <div className="container_9_text" >
                <div>
                  <img  src={Towerlight_text} className="container_9_picture" alt=""/>
                </div>
                <div className="container_9__text-detail">
                  <p >
                  Signal Tower Light รุ่นมาตรฐาน คือ LR5 , LR6 สามารถรับสัญญาณได้8 ถึงสัญญาณ จะมาพร้อมไฟ LED 3สี โดยสีมาตรฐาน คือ สีแดง สีเขียว สีเหลือง สามารถเพิ่มเป็นรุ่นไฟ LED 5 สี ได้ด้วยสีนํา้าเงินและสีขาว
                  </p>
                </div>
              </div>
            </div>
          </Sticky>

          <Sticky top={8} enabled={true}>
            <div className="container_10" >
                <h1 className="container_10__text">ติดตั้งง่าย ไร้สาย<br/>ไม่ขึ้นกับยี่ห้อของเครื่องจักรหรือแบรนด์ใดๆ</h1>
              <video className="container__10__video" autoPlay={true} loop={true} muted={true} src={Install_Towerlight} />
            </div>
          </Sticky>

          <Sticky top={9} enabled={true} >
            <div className="container__11"  >
              <Fade up  duration={2500} >
                <h4 className="container__11_text-header">ตอบโจทย์การรองรับเซิร์ฟเวอร์ของคุณ</h4>
              </Fade>
              <div className="container__11_2">
                <Fade left  duration={2500} >
                  <div>
                    <img className="container__11_picture" src={Server_OnPermise} alt=""/>
                    <h4 className="container__11_text-right">ON - PREMISE</h4>
                  </div>
                </Fade>   
                <Fade right  duration={2500} >
                  <div>
                    <img className="container__11_picture" src={Server_aws} alt=""/>
                    <h4 className="container__11_text-right">ON - CLOUD</h4>
                  </div>
                </Fade>
              </div>
            </div>
          </Sticky>
        </div>
    </div>
  );
}

export default Home2;
