import React , {useState,useEffect}from 'react'


import Header from './Header'
import  '../assets/scss/usecase.css'
import SEESETGraphic from '../assets/img/usecase/SEESETGraphic.png'
import Move_TowerlightStatus from '../assets/img/usecase/Move_TowerlightStatus.gif'
import Move_Worker from '../assets/img/usecase/Move_Worker.gif'
import Move_Order from '../assets/img/usecase/Move_Order.gif'
import Move_Repair_Test from '../assets/img/usecase/Move_Repair_Test.gif'
import Move_Think_test from '../assets/img/usecase/Move_Think_test.gif'
import Move_LineMachine from '../assets/img/usecase/Move_LineMachine.gif'
import HambergaerMenu from './HambergaerMenu'
import {Fade} from 'react-reveal';
import $ from 'jquery';
const Usecase = () => {
    const [hamshow , setHamshow ] = useState(false)
    useEffect(()=>{
        if(hamshow){
            function name() {
                $('.usecase__container__scroll456').addClass('usecase__container__scroll457').removeClass('usecase__container__scroll456');
            }
            name()
        }else{
            function name() {
            $('.usecase__container__scroll457').addClass('usecase__container__scroll456').removeClass('usecase__container__scroll457');
        }
            name()
        }
    },[hamshow])
    return (
        <div className="usecase_main">
            <div id="usecase_container__header">
                <Header  />
                <HambergaerMenu togglehamberger={setHamshow}/>

            </div>
            <div className="usecase__container__scroll456" style={{overflowX:'hidden'}}>
                <div className="usecase_section1__container">
                    <Fade left  duration={2000} >
                    <div>
                        <div className="usecase_item1" >
                        </div>
                        <div className="usecase_item2">
                            <h1>ผลการสนับสนุนการพัฒนา</h1>
                            <p>โซลูชั่นช่วยคุณเปลี่ยนแปลงโรงงานอุตสาหกรรมให้เข้าสู่ยุค Industry 4.0 ให้การเก็บข้อมูลจากเครื่องจักรเป็นเรื่องง่ายๆในรูปแบบข้อมูล Digital พร้อมสำหรับการวิเคราะห์ตามความต้องการต่างๆ ทั้งหาสาเหตุของปัญหาที่เกิดจากการผลิต ความต้องการลดความสูญเสีย ทำกิจกรรม Kaizen และ Lean production manufacturing</p>
                        </div>
                    </div>
                    </Fade>
                </div>

                <div className="usecase_section2__container">
                    <div className="usecase_bg1">
                    </div>
                    <div>
                        <Fade left  duration={2000} >
                        <div className="usecase_item3">
                            <span><h1>ภาพทั้งโรงงานจะอยู่บนอุปกรณ์ในมือคุณ</h1></span>
                            <p>โรงงานที่มีขนาดกว้างใหญ่การตรวจสอบสถานะการทำงานของเครื่องจักร เป็นเรื่องที่เป็นไปได้ยากและใช้เวลา แต่ SEESET จะช่วยให้คุณมองเห็นทั้งโรงงานของคุณในหน้าจอเดียว สามารถเปิดบนอุปกรณ์มากมายผ่าน web browser</p>
                        </div>
                        </Fade>
                        <Fade right  duration={2000} >
                        <div className="usecase_item4">
                            <img src={SEESETGraphic} alt=""/>
                        </div>
                        </Fade>
                    </div>
                </div>

                <div className="usecase_section3__container">
                    <div  >
                        <div>
                            <Fade left  duration={2000} >
                            <div className="usecase_item5">
                                <img src={Move_TowerlightStatus} alt=""/>
                            </div>
                            </Fade>

                            <Fade right  duration={2000} >
                            <div className="usecase_item6">
                                <span><h1>เครื่องจักรทำงานไม่เต็มประสิทธิภาพ</h1></span>
                                <p>SEESET จะบอกถึงช่วงเวลา และสาเหตุที่ทำให้เครื่องหยุด ซึ่งจะก่อให้เกิด การผลิตที่ล่าช้า ผลิตได้ไม่ทันตามเวลาที่กำหนด เมื่อนำข้อมูลที่ได้จาก SEESET มาพัฒนา และแก้ไขปัญหาให้ตรงจุด จะทำให้เครื่องจักรสามารถผลิตได้เต็มประสิทธิภาพ โดยไม่จำเป็นต้องซื้อเครื่องจักรเพิ่ม</p>
                            </div>
                            </Fade>
                        </div>
                    </div>
                </div>

                <div className="usecase_section4__container">
                    <div>
                        <div className="usecase_section4__grid">
                            <Fade right  duration={2000} >
                            <div className="usecase_item7">
                                <div>

                                </div>
                            </div>
                            </Fade>

                            <Fade left  duration={2000} >
                            <div className="usecase_item8">
                                <span><h1>เครื่องจักรผลิตไม่ต่อเนื่อง</h1></span>
                                <p>สถิติของการ Idle (ว่าง) จากระบบถูกพบเป็นจำนวนมาก ซึ่งทั้งฝ่ายซ่อมบำรุงและฝ่ายผลิต พยายามวิเคราะห์หาสาเหตุ โดยเฝ้าสังเกตุพบปัญหาสำคัญ ซึ่งไม่ได้เกิดจากตัวเครื่องจักรเอง แต่เป็นเกิดจากต้องรอ กล่องหรือลังที่มีลักษณะเฉพาะสำหรับใส่สินค้าที่ผลิตออกมา ทำให้หมุนเวียนต่อการใช้งานไม่เพียงพอ จึงได้ข้อสรุปในการจัดทำกล่องเฉพาะนี้เพิ่มขึ้น เพื่อลดช่องว่างในการผลิตของเครื่องจักร ให้สามารถผลิตได้อย่างต่อเนื่อง</p>
                            </div>
                            </Fade>
                        </div>
                    </div>
                </div>

                <div className="usecase_section5__container">
                    <div className="usecase_container5">
                        <div>
                            <Fade left  duration={2000} >
                            <div className="usecase_item9">
                                <div>

                                </div>
                                <img src={Move_LineMachine} alt=""/>
                            </div>
                            </Fade>

                            <Fade right  duration={2000} >
                            <div className="usecase_item10">
                                <span><h1>Line Status</h1></span>
                                <p>สายงานการผลิตรูปแบบ Assembly line มีการผลิตหลายขั้นตอน การติดตั้งระบบ SEESET ทำให้หัวหน้างานหรือฝ่ายซ่อมบำรุง สามารถรู้จุดที่เกิดความขัดข้องหรือผิดพลาดได้ในทันที ซึ่งช่วยลดระยะเวลาในการซ่อมแซม นอกจากนี้เวลาที่เกิดเหตุขัดข้อง SEESET จะบันทึกเวลาที่เกิดเหตุการณ์นั้นๆ จนกระทั่งเหตุการณ์นั้นสิ้นสุดลง พร้อมยังบันทึกสาเหตุของการเกิดเหตุการณ์ดังกล่าวได้ เพื่อนำไปวิเคราะห์ ป้องกันและแก้ไข ไม่ให้เกิดปัญหานั้นซ้ำอีก</p>
                            </div>
                            </Fade>
                        </div>
                        
                    </div>
                </div>

                <div className="usecase_section6__container">
                    <div>
                        <Fade right  duration={2000} >
                        <div className="usecase_item11">
                            <img src={Move_Worker} alt=""/>
                        </div>
                        </Fade>

                        <Fade left  duration={2000} >
                        <div className="usecase_item12">
                            <span><h1>การปฏิบัติงานของพนักงาน</h1></span>
                            <p>คุณจะรู้ได้อย่างไรว่า พนักงานปฏิบัติงานได้เต็มเวลา และเต็มประสิทธิภาพ มีกรณีศึกษา 1 กรณี เครื่องจักรมี run rate 100% แต่ไม่มีผลผลิตออกมา หรือออกมาน้อย เมื่อสอบถามพนักงานแล้ว พนักงานแจ้งว่า เครื่องจักรผลิตช้า มักจะพบสาเหตุดังกล่าวในกะการทำงานช่วงกลางคืน เมื่อติดตั้งระบบ SEESET แล้ว ปรากฏว่า พนักงานปล่อยให้เครื่อง Run ไปเฉยๆ โดยไม่เติมวัตถุดิบ กักตุนจำนวนการผลิตไว้ เพื่อจะได้เอาไว้ทำในช่วง O.T. ในวันต่อๆไป</p>
                        </div>
                        </Fade>
                    </div>
                </div>

                <div className="usecase_section7__container">
                    <div>
                        <Fade left  duration={2000} >
                        <div className="usecase_item13">
                            <img src={Move_Order} alt=""/>
                        </div>
                        </Fade>

                        <Fade right  duration={2000} >
                        <div className="usecase_item14">
                            <span><h1>มีออเดอร์จำนวนมาก ผลิตไม่ทัน</h1></span>
                            <p>พนักงานทำเรื่องขอซื้อเครื่องจักรเพิ่ม เนื่องจากผลิตไม่ทันตาม Order ทางผู้บริหารมีการวิเคราะห์ถึงสาเหตุที่แท้จริง โดยการ monitor การทำงานของเครื่องจักร พบว่า เครื่องจักรมีเหตุการณ์ที่ทำให้หยุดผลิตบ่อยครั้ง จึงทำการติดตั้งระบบ SEESET เพื่อเก็บข้อมูล พบว่าเครื่องจักรเกิด Alarm สูงมาก เนื่องจาก Mold หรือแม่พิมพ์มีปัญหา จากที่จะต้องลงทุนซื้อเครื่องจักรใหม่มูลค่าหลายสิบล้าน นำข้อมูลที่ได้ มาแก้ไข Mold ทำให้ประสิทธิภาพการทำงานของเครื่องจักรเป็นไปอย่างต่อเนื่อง สามารถสร้างผลผลิตได้จำนวนมากขึ้น ในระยะเวลาเท่าเดิม</p>
                        </div>
                        </Fade>
                    </div>
                </div>

                <div className="usecase_section8__container">
                    <div>
                        <Fade left  duration={2000} >
                        <div className="usecase_item15">
                            <img src={Move_Repair_Test} alt=""/>
                        </div>
                        </Fade>

                        <Fade right  duration={2000} >
                        <div className="usecase_item16">
                            <span><h1>ซ่อมแซมเครื่องจักรได้ทันที</h1></span>
                            <p>รู้ได้ทันทีเมื่อเครื่องจักรเกิดปัญหาขึ้น ทำให้สามารถเข้าไปแก้ปัญหาได้อย่างรวดเร็ว ลดเวลาการแจ้งปัญหามายัง Maintanace เพื่อให้การผลิตดำเนินการต่อได้ตรงตามแผนการผลิตที่ถูกวางเอาไว้</p>
                        </div>
                        </Fade>
                    </div>
                </div>

                <div className="usecase_section9__container">
                    <div>
                        <Fade left  duration={2000} >
                        <div className="usecase_item17">
                            <img src={Move_Think_test} alt=""/>
                        </div>
                        </Fade>

                        <Fade right  duration={2000} >
                        <div className="usecase_item18">
                            <span><h1>เข้าใจแผนการผลิตของวันอย่างรวดเร็ว</h1></span>
                            <p>แสดงแผนการผลิตแบบทั้งวัน ซึ่งเหมาะกับผู้รับผิดชอบหน้าเครื่องจักร วันนี้เครื่องไหนต้องผลิตอะไรบ้าง และเป็นไปตามแผนการผลิตหรือไม่</p>
                        </div>
                        </Fade>
                    </div>
                </div>


                <div className="usecase_section10__container">
                    <h1>
                        สนใจตัวช่วยในการพัฒนาติดต่อเรา
                    </h1>
                    <p>
                        คุณจะรู้ได้อย่างไรว่า ข้อมูลที่คุณนำมาพัฒนานั้นถูกต้องและน่าเชื่อถือเพียงพอ 
                        เราคือผู้พัฒนาซอร์ฟแวร์ที่จะช่วยให้คุณสามารถรู้ทุกความเคลื่อนไหวในการผลิตได้แบบ Real-Time อีกทั้งยังบันทึกข้อมูลเหล่านั้นมาสรุปให้คุณสามารถคำไปวิเคราะห์ต่าง ๆ ได้อย่างมากมาย
                    </p>
                    <button class="button_usecase button_usecase1"><a rel="noreferrer" href="https://outlook.office365.com/owa/calendar/ZenalyseCoLtd@zenalyse.co.th/bookings/" target="_blank" className="text-link">ติดต่อเรา</a></button>
                </div>
            </div>
        </div>
    )
}

export default Usecase
