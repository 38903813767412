import React ,{useRef, useState}from 'react'
import { Twirl as Hamburger } from 'hamburger-react'
import '../assets/scss/hambergermenu.css'
import {
    Link
  } from "react-router-dom";
const HambergaerMenu = ({togglehamberger}) => {
    const [isOpen , setIsOpen] = useState(false)
    const show = useRef()
    const fn = () => {
        setIsOpen(!isOpen)
        togglehamberger(!isOpen)
        show.current.classList.add(!isOpen ? 'hamberger__container2' : "hamberger__container" )
        show.current.classList.remove(!isOpen ? "hamberger__container" : "hamberger__container2")
        
    }
    const hamberger = (
        <div style={{width:'100%',height:"100%",display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'column',backgroundColor:'black'}}>
            <div className="line_show" />
            <Link to="/home" className="deleteLine_react_router_dom"><h1>Home</h1></Link>
            <div className="line_show" />
            <Link to="/product" className="deleteLine_react_router_dom"><h1>รายละเอียดผลิตภัณฑ์</h1></Link>
            <div className="line_show" />
            <Link to="/usecase" className="deleteLine_react_router_dom"><h1>กรณีศึกษา</h1></Link>
            <div className="line_show" />
            <Link to="/pricing" className="deleteLine_react_router_dom"><h1>แพ็กเกจของเรา</h1></Link>
            <div className="line_show" />
            <Link to="/contact" className="deleteLine_react_router_dom"><h1>ติดต่อเรา</h1></Link>
            <div className="line_show" />
        </div>
    )
    return (
        <div className="hamberger__container" ref={show}>
            <div onClick={fn} className="hambergerMenu">
                <Hamburger toggled={isOpen} color="white"/>
            </div>
            {isOpen && hamberger}
        </div>
    )
}

export default HambergaerMenu
